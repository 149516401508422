.logo {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    height: 28px;
    text-align: center;
    color: var(--logo-color);
}

.logo svg {
    height: 28px;
}

.logo.isOnboarding {
    height: 20px;
}

.logo.isSimple {
    height: 24px;
}

.logo.isFastEasy {
    height: 30px;
    font-size: 16px;
    line-height: 22px;
    text-transform: unset;
    color: var(--logo-color-fast);
}

.logoImgDefault {
    max-width: 28px;
    max-height: 28px;
    margin-right: 10px;
    border-radius: 5px;
}

.logoImgDefault svg {
    width: 28px;
    height: 28px;
}

.logoImgFastEasy {
    width: 24px;
    height: 24px;
    max-width: unset;
    max-height: unset;
    margin-right: 12px;
    border-radius: 5px;
}

.logoImgMr8 {
    margin-right: 8px;
}

.logoImgOnboarding {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 5px;
}

.logoFooter {
    width: 40px;
    height: 40px;
}

.centered {
    margin: 0 auto;
}

.biggerLogoText {
    font-size: 16px !important;
    line-height: 24px !important;
}

.zIndexSmaller {
    z-index: 99;
}
